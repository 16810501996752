import React, { useState, useEffect, Fragment } from 'react';
import './index.scss';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next'
import Host from '../../Components/Host';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import Button from '@mui/joy/Button';
import { useSelector, useDispatch } from "react-redux";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import ReCAPTCHA from "react-google-recaptcha";
import { useCreateInquiriesMutation } from '../../services/inquiries';
import { addToInquiry } from "../../Reducers/authReducer";
import HelmetComponent from '../../Components/HelmetComponent';

function ContactUs() {
  const { userId, userToken, userEmail, userName, isMember, mediaLang, countryCode, languageCode, isInquiry } = useSelector((state) => state.auth);
  const { t } = useTranslation()
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const dispatch = useDispatch();
  const [createinquiry, createresult] = useCreateInquiriesMutation()

  const [getPhone, setPhone] = useState('')
  const [getPhoneError, setPhoneError] = useState(false)
  const [isAlready, setAlready] = useState(false)
  const recaptchaRef = React.useRef();

  async function createInquiryFrom(data) {
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    if (isValidPhoneNumber(getPhone)) {
      if (isInquiry) {
        setAlready(true)
      } else {
        createinquiry({
          name: data.name,
          email: data.email,
          message: data.message,
          phone: getPhone,
          language: languageCode,
          country: countryCode,
          captcha_token: token,
          domain: Host?.domain,
          domainEmail: Host?.email
        })
      }
    } else {
      setPhoneError(true)
    }
  }

  useEffect(() => {
    if (createresult?.isSuccess) {
      dispatch(addToInquiry({
        isInquiry: true,
      }));
    }
  }, [createresult]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])


  function checkExist() {
    if (createresult?.isSuccess) {
      return true
    } else if (isAlready) {
      return true
    } else {
      return false
    }
  }


  function googlMap() {
    const thwiftyAll = "thwifty";
    const goshoprightAll = "goshopright";
    const hostName = window.location.hostname?.split(".");
    const domain = hostName?.includes(thwiftyAll) ? 'thwifty' : 'goshopright';
    let gtmId;
    switch (domain) {
      case 'thwifty':
        gtmId = '';
        break;
      case 'goshopright':
        gtmId = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3607.5063016748168!2d55.319096883144574!3d25.28718812003335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5caf35a16519%3A0xd1b8ab67fe911724!2sf12%20-%201%20Hor%20Al%20Anz%20St%20-%20Abu%20Hail%20-%20Dubai!5e0!3m2!1sen!2sae!4v1721397714905!5m2!1sen!2sae';
        break;
      default:
        gtmId = '';
        break;
    }
    return (
      <>
        {domain === 'thwifty' ? '' :
          <>
          {/* <iframe src={gtmId} width="100%" height="350" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
          </>
        }
      </>
    )
  }

  return (
    <>
      <HelmetComponent title={'Contact Us'} />
      <div className="statisc-page">

        <div className="container static-container">
          <div className="row static-row">

            <div className="col-md-10">

              <h1 style={{ padding: '2rem 0rem 2rem 0rem', fontWeight: 300, fontSize: '1.4rem' }}>
                Contact Us</h1>
              <div className="row" style={{ justifyContent: 'center' }}>
                <div className="col-md-6">
                  {googlMap()}
                  <div className='cp-phone-section' style={{ display: 'flex', padding: 0 }}>
                    <div style={{ margin: '10px 0px', fontSize: '1rem' }}>
                      <div style={{ color: '#363535', fontWeight: 400, fontSize: '1rem' }}>
                        {/* <h3 style={{ fontSize: '1.5rem' }}>
                          Address:
                        </h3> */}
                      </div>
                      <div style={{ fontSize: '1rem' }}>
                        <div dangerouslySetInnerHTML={{
                          __html: Host?.address
                        }}>
                        </div>
                        <p> <MailOutlineIcon style={{ fontSize: '18px' }} /> {Host?.email} </p>
                        <p> <PhoneIcon style={{ fontSize: '18px' }} /> {Host?.phone} </p>

                      </div>
                    </div>
                  </div>

                </div>

                <div className="col-md-6">
                  <div className="static-container-card"
                    style={{ textAlign: 'left', padding: '20px', background: '#fcfcfc', border: '1px solid #eee', borderRadius: '7px' }}>
                    <h4 style={{ padding: '20px 0px 20px 0px' }}>
                      {t('Send us your query')}
                    </h4>
                    {checkExist() ?
                      <>
                        {!createresult?.isSuccess && isAlready ?
                          <div style={{ textAlign: 'center' }} className='alert alert-danger'>Your inquiry already has been received. We will get back to you shortly.</div> :
                          <div style={{ textAlign: 'center' }} className='alert alert-success'>Your inquiry has been received. We will get back to you shortly.</div>
                        }
                      </>
                      :
                      <form onSubmit={handleSubmit(createInquiryFrom)}>
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          size="invisible"
                          sitekey="6LfD62EnAAAAAJ83k0BznR8m_cXInxBFjtTokspL"
                        />
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label style={{ fontSize: '14px' }}>{t('name')} </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                aria-invalid={errors.name ? "true" : "false"}
                                {...register('name', { required: true })} />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <label style={{ fontSize: '14px' }}>{t('email')} </label>
                              <input
                                type="text"
                                className="form-control"
                                id="email"
                                aria-invalid={errors.email ? "true" : "false"}
                                {...register('email', { required: true })} />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <label style={{ fontSize: '14px' }}>{t('phone')} </label>
                              <PhoneInput
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry={countryCode}
                                value={getPhone}
                                onChange={setPhone}
                              />
                              {getPhoneError &&
                                <>
                                  {getPhone && isValidPhoneNumber(getPhone) ? '' :
                                    <div><small style={{ color: 'tomato' }}>{'Invalid phone number'}</small></div>
                                  }
                                </>
                              }
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <label style={{ fontSize: '14px' }}>{t('message')} </label>
                              <textarea
                                type="text"
                                className="form-control"
                                id="message"
                                aria-invalid={errors.message ? "true" : "false"}
                                {...register('message', { required: true })}>
                              </textarea>

                            </div>
                          </div>

                          <div className="col-md-12 Header-signin-button">
                            <div className="form-group">
                              <Button
                                disabled={createresult.isLoading}
                                style={{ background: Host?.bgColor, color: Host?.buttonColor, width: '100%', height: '45px' }}
                                type="submit">
                                {createresult.isLoading ? t('loading') : t('submit')}
                              </Button>

                            </div>
                          </div>
                        </div>
                      </form>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </>
  );
}

export default ContactUs;


// import { Helmet } from "react-helmet";
import { Helmet } from 'react-helmet-async';
import Host from "./Host";
import AllDomains from "./Header/AllDomains";

export default function HelmetComponent({ title }) {

  // function checthisout(thisdata) {
  //   if (hostName?.includes(thwiftyAll)) {
  //     return thisdata?.thwifty?.map((item) =>
  //       <link rel="alternate" href={`${item?.domain}${window.location.pathname}`} />
  //     )
  //   } else if (hostName?.includes(goshoprightAll)) {
  //     return thisdata?.goshopright?.map((item) =>
  //       <link rel="alternate" href={`${item?.domain}${window.location.pathname}`} />
  //     )
  //   } else {
  //     return thisdata?.thwifty?.map((item) =>
  //       <link rel="alternate" href={`${item?.domain}${window.location.pathname}`} />
  //     )
  //   }
  // }

  return (
    <Helmet>
      <title>{`${title ? title : ''} ${title && '|'} ${Host?.metaTitle}`}</title>
    </Helmet>
  )
}

import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import PayBy from './PayBy';
import Tap from './Tap';

function PayByPurchaseGuestTest() {
  const location = useLocation();

  const { tap } = useParams();

  return (
    <>

      {tap === 'tap' ?
        <Tap />
        :
        <PayBy />
      }

    </>
  );
}

export default PayByPurchaseGuestTest;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const homepageApi = createApi({
  reducerPath: 'homepageApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Homepage'],
  refetchOnMountOrArgChange: 1,
  keepUnusedDataFor: 86400000,
  endpoints: (builder) => ({
    getTrending: builder.query({
      query: (data) => {
        return {
          url: `api/homepage/trending`,
          method: 'GET',
          headers: {
            'X-Country-Code': data.countryCode,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      },
      keepUnusedDataFor: 86400000,
      providesTags: ['Homepage'],
    }),

    getPopular: builder.query({
      query: (data) => {
        return {
          url: `api/homepage/popular`,
          method: 'GET',
          headers: {
            'X-Country-Code': data.countryCode,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      },
      keepUnusedDataFor: 86400000,
      providesTags: ['Homepage'],
    }),

    getRelated: builder.query({
      query: (data) => {
        return {
          url: `api/product/related`,
          method: 'GET',
          headers: {
            'X-Country-Code': data.countryCode,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      },
      keepUnusedDataFor: 86400000,
      providesTags: ['Homepage'],
    }),

  }),

})

export const { useGetTrendingQuery, useGetPopularQuery, useGetRelatedQuery } = homepageApi
const Domains  = [
  {
    domain: ['ae.thwifty.com', 'ae.goshopright.com'],
    code: 'AE'
  },
  {
    domain: ['ae-en.thwifty.com', 'ae-en.goshopright.com'],
    code: 'AE'
  },
  {
    domain: ['auh.thwifty.com', 'auh.goshopright.com'],
    code: 'AE'
  },
  {
    domain: ['auh-en.thwifty.com', 'auh-en.goshopright.com'],
    code: 'AE'
  },
  {
    domain: ['dxb.thwifty.com', 'dxb.goshopright.com'],
    code: 'AE'
  },
  {
    domain: ['dxb-en.thwifty.com', 'dxb-en.goshopright.com'],
    code: 'AE'
  },
  {
    domain: ['en-ae.thwifty.com', 'en-ae.goshopright.com'],
    code: 'AE'
  },
  {
    domain: ['en-auh.thwifty.com', 'en-auh.goshopright.com'],
    code: 'AE'
  },
  {
    domain: ['en-dxb.thwifty.com', 'en-dxb.goshopright.com'],
    code: 'AE'
  },
  {
    domain: ['en-uae.thwifty.com', 'en-uae.goshopright.com'],
    code: 'AE'
  },
  {
    domain: ['uae.thwifty.com', 'uae.goshopright.com'],
    code: 'AE'
  },
  {
    domain: ['uae-en.thwifty.com', 'uae-en.goshopright.com'],
    code: 'AE'
  },
  {
    domain: ['en-ksa.thwifty.com', 'en-ksa.goshopright.com'],
    code: 'SA'
  },
  {
    domain: ['en-sa.thwifty.com', 'en-sa.goshopright.com'],
    code: 'SA'
  },
  {
    domain: ['en-sar.thwifty.com', 'en-sar.goshopright.com'],
    code: 'SA'
  },
  {
    domain: ['en-saudi.thwifty.com', 'en-saudi.goshopright.com'],
    code: 'SA'
  },
  {
    domain: ['ksa.thwifty.com', 'ksa.goshopright.com'],
    code: 'SA'
  },
  {
    domain: ['ksa-en.thwifty.com', 'ksa-en.goshopright.com'],
    code: 'SA'
  },
  {
    domain: ['sa.thwifty.com', 'sa.goshopright.com'],
    code: 'SA'
  },
  {
    domain: ['sa-en.thwifty.com', 'sa-en.goshopright.com'],
    code: 'SA'
  },
  {
    domain: ['sar.thwifty.com', 'sar.goshopright.com'],
    code: 'SA'
  },
  {
    domain: ['sar-en.thwifty.com', 'sar-en.goshopright.com'],
    code: 'SA'
  },
  {
    domain: ['saudi.thwifty.com', 'saudi.goshopright.com'],
    code: 'SA'
  },
  {
    domain: ['saudi-en.thwifty.com', 'saudi-en.goshopright.com'],
    code: 'SA'
  },
  {
    domain: ['canada.thwifty.com', 'canada.goshopright.com'],
    code: 'CA'
  },
  {
    domain: ['can.thwifty.com', 'can.goshopright.com'],
    code: 'CA'
  },
  {
    domain: ['ca.thwifty.com', 'ca.goshopright.com'],
    code: 'CA'
  },
  {
    domain: ['en-canada.thwifty.com', 'en-canada.goshopright.com'],
    code: 'CA'
  },
  {
    domain: ['en-can.thwifty.com', 'en-can.goshopright.com'],
    code: 'CA'
  },
  {
    domain: ['en-ca.thwifty.com', 'en-ca.goshopright.com'],
    code: 'CA'
  },
  {
    domain: ['canada-en.thwifty.com', 'canada-en.goshopright.com'],
    code: 'CA'
  },
  {
    domain: ['can-en.thwifty.com', 'can-en.goshopright.com'],
    code: 'CA'
  },
  {
    domain: ['ca-en.thwifty.com', 'ca-en.goshopright.com'],
    code: 'CA'
  },
  {
    domain: ['kuwait.thwifty.com', 'kuwait.goshopright.com'],
    code: 'KW'
  },
  {
    domain: ['kw.thwifty.com', 'kw.goshopright.com'],
    code: 'KW'
  },
  {
    domain: ['kwd.thwifty.com', 'kwd.goshopright.com'],
    code: 'KW'
  },
  {
    domain: ['kwt.thwifty.com', 'kwt.goshopright.com'],
    code: 'KW'
  },
  {
    domain: ['en-kuwait.thwifty.com', 'en-kuwait.goshopright.com'],
    code: 'KW'
  },
  {
    domain: ['en-kw.thwifty.com', 'en-kw.goshopright.com'],
    code: 'KW'
  },
  {
    domain: ['en-kwd.thwifty.com', 'en-kwd.goshopright.com'],
    code: 'KW'
  },
  {
    domain: ['en-kwt.thwifty.com', 'en-kwt.goshopright.com'],
    code: 'KW'
  },
  {
    domain: ['kuwait-en.thwifty.com', 'kuwait-en.goshopright.com'],
    code: 'KW'
  },
  {
    domain: ['kw-en.thwifty.com', 'kw-en.goshopright.com'],
    code: 'KW'
  },
  {
    domain: ['kwd-en.thwifty.com', 'kwd-en.goshopright.com'],
    code: 'KW'
  },
  {
    domain: ['kwt-en.thwifty.com', 'kwt-en.goshopright.com'],
    code: 'KW'
  },
  {
    domain: ['oman.thwifty.com', 'oman.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['muscat.thwifty.com', 'muscat.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['om.thwifty.com', 'om.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['oma.thwifty.com', 'oma.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['omn.thwifty.com', 'omn.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['omr.thwifty.com', 'omr.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['en-oman.thwifty.com', 'en-oman.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['en-muscat.thwifty.com', 'en-muscat.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['en-om.thwifty.com', 'en-om.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['en-oma.thwifty.com', 'en-oma.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['en-omn.thwifty.com', 'en-omn.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['en-omr.thwifty.com', 'en-omr.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['oman-en.thwifty.com', 'oman-en.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['muscat-en.thwifty.com', 'muscat-en.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['om-en.thwifty.com', 'om-en.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['oma-en.thwifty.com', 'oma-en.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['omn-en.thwifty.com', 'omn-en.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['omr-en.thwifty.com', 'omr-en.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['uk.thwifty.com', 'uk.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['gb.thwifty.com', 'gb.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['gbr.thwifty.com', 'gbr.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['gbp.thwifty.com', 'gbp.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['eng.thwifty.com', 'eng.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['unitedkingdom.thwifty.com', 'unitedkingdom.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['britain.thwifty.com', 'britain.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['british.thwifty.com', 'british.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['england.thwifty.com', 'england.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['en-uk.thwifty.com', 'en-uk.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['en-gb.thwifty.com', 'en-gb.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['en-gbr.thwifty.com', 'en-gbr.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['en-gbp.thwifty.com', 'en-gbp.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['en-eng.thwifty.com', 'en-eng.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['en-unitedkingdom.thwifty.com', 'en-unitedkingdom.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['en-britain.thwifty.com', 'en-britain.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['en-british.thwifty.com', 'en-british.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['en-england.thwifty.com', 'en-england.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['uk-en.thwifty.com', 'uk-en.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['gb-en.thwifty.com', 'gb-en.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['gbr-en.thwifty.com', 'gbr-en.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['gbp-en.thwifty.com', 'gbp-en.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['eng-en.thwifty.com', 'eng-en.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['unitedkingdom-en.thwifty.com', 'unitedkingdom-en.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['britain-en.thwifty.com', 'britain-en.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['british-en.thwifty.com', 'british-en.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['england-en.thwifty.com', 'england-en.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['bahrain.thwifty.com', 'bahrain.goshopright.com'],
    code: 'BH'
  },
  {
    domain: ['bh.thwifty.com', 'bh.goshopright.com'],
    code: 'BH'
  },
  {
    domain: ['bhd.thwifty.com', 'bhd.goshopright.com'],
    code: 'BH'
  },
  {
    domain: ['en-bahrain.thwifty.com', 'en-bahrain.goshopright.com'],
    code: 'BH'
  },
  {
    domain: ['en-bh.thwifty.com', 'en-bh.goshopright.com'],
    code: 'BH'
  },
  {
    domain: ['en-bhd.thwifty.com', 'en-bhd.goshopright.com'],
    code: 'BH'
  },
  {
    domain: ['bahrain-en.thwifty.com', 'bahrain-en.goshopright.com'],
    code: 'BH'
  },
  {
    domain: ['bh-en.thwifty.com', 'bh-en.goshopright.com'],
    code: 'BH'
  },
  {
    domain: ['bhd-en.thwifty.com', 'bhd-en.goshopright.com'],
    code: 'BH'
  }
];

export default Domains;
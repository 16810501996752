import React, { Component } from 'react';
import { Redirect, Link } from "react-router-dom";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Button from '@mui/material/Button';
import { useSelector } from "react-redux";
function NotFound() {
  const { userId, userToken, cartToken, languageCode, countryCode } = useSelector((state) => state.auth);

  let fontFamilyForAll
  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else if (languageCode === 'urdu') {
    fontFamilyForAll = 'AlQalam Shakir'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }
  const fontFamily = {
    fontFamily: fontFamilyForAll,
  }

  return (
    <div style={{
      padding: '100px 0px 100px 0px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>


      <div className='container'>
        <div className="row" style={{ justifyContent: 'center' }}>
          <div className="col-md-6" style={{ textAlign: 'center' }}>
            <div className="form-group" style={{ border: '1px solid #eee', borderRadius: '6px', padding: '20px' }}>
              <InfoOutlinedIcon style={{ fontSize: '3rem', color: '#e2e3e5', marginBottom: '10px' }} />
              <div className='alert alert-secondary' style={{ textAlign: 'center', fontSize: '1rem', fontWeight: 400 }}>Page not found!</div>

              <div className="form-group">
                <Button
                  variant='outlined'
                  style={fontFamily}
                  component={Link}
                  to={`/`}
                >
                  <div style={{ textTransform: 'capitalize', fontWeight: 400 }}>Continue Shopping</div>
                </Button>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div >
  );
}

export default NotFound;

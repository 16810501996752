import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useGetOrderQuery } from '../../../services/membership';
import { useSelector } from "react-redux";
import Host from '../../../Components/Host';
import { Tag } from "antd";
import { useGetUserDataQuery } from '../../../services/login';
import StepButton from '@mui/joy/StepButton';
import Check from '@mui/icons-material/Check';
import formatPrice from '../../../Components/formatPrice';
import IconButton from '@mui/joy/IconButton';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import Stepper from '@mui/joy/Stepper';
import Step, { stepClasses } from '@mui/joy/Step';
import StepIndicator, { stepIndicatorClasses } from '@mui/joy/StepIndicator';
import Typography, { typographyClasses } from '@mui/joy/Typography';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import AppRegistrationRoundedIcon from '@mui/icons-material/AppRegistrationRounded';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import LocalMallIcon from '@mui/icons-material/LocalMall';


var moment = require('moment');
function OrdersList({ orders }) {
  const { userId, userToken, userEmail, countryCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  if (!userEmail && !userToken) {
    history.push('/')
  }
  const { data: userData } = useGetUserDataQuery({ userId: userId, userToken: userToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 24

  const membership = useGetOrderQuery({
    page: page,
    size: limit,
    userId: userId,
    userToken: userToken,
    domain: Host?.domain,
    countryCode: countryCode
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const membershipData = membership?.data?.order_items
  const pagination = membership?.data?.pagination

  function getDeliverydays(data, created) {
    if (Number(data) > 0) {
      return <>{moment(created).add(Number(data), 'days').format('dddd Do MMM YYYY')}</>
    } else {
      return moment(created).add(10, 'days').format('dddd Do MMM YYYY')
    }
  }

  const groupData = orders?.order_items?.reduce((acc, obj) => {
    const key = obj.estimated_delivery;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});

  function getStateColor(state) {
    if (state === 'accepted') {
      return 'green'
    } else if (state === 'cancelled') {
      return 'error'
    } else {
      return 'warning'
    }
  }




  function fetchStepColor(current, next, states) {
    if (states?.includes(current)) {
      if (states?.includes(next)) {
        return { color: 'success', variant: 'solid', completed: true, active: false, disabled: false }
      } else {
        return { color: 'primary', variant: 'solid', completed: false, active: true, disabled: false }
      }
    } else {
      return { color: 'neutral', variant: 'outlined', completed: false, active: false, disabled: true }
    }

  }


  const [isExpand, setExpand] = useState('');

  function openTracking(data) {
    setExpand(data)
  }



  function getShippingStep(thisData) {
    const values = thisData?.split(',');
    if (values?.some(part => part.includes('arrived'))) {
      return true
    } else if (values?.some(part => part.includes('departed'))) {
      return true
    } else if (thisData.includes('out_for_delivery')) {
      return true
    } else {
      return false
    }
  }

  function getShippingStep1(thisData) {
    const values = thisData?.split(',');
    if (values?.some(part => part.includes('arrived'))) {
      return true
    } else if (values?.some(part => part.includes('departed'))) {
      return true
    } else if (thisData.includes('out_for_delivery')) {
      return true
    } else {
      return false
    }
  }


  return (
    <>
      {groupData && Object?.entries(groupData).map(([group, groupItems]) => (
        <div
          key={group}
          style={{
            border: '1px solid #eee',
            borderRadius: '10px',
            padding: '15px',
            background: '#f0f4f8',
            marginBottom: '15px'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: '10px' }}>
            <div style={{ fontSize: '14px', fontWeight: 500 }}>
              {t('delivery_by')}&nbsp;{getDeliverydays(group, orders?.createdAt)}
            </div>
            <div style={{ fontSize: '14px', fontWeight: 500 }}>{t('items')}: {groupItems?.length}</div>
          </div>
          <div className='op-item-section'>
            {groupItems?.map((items, index) =>
              <div
                key={index}
                style={{ background: '#fff', padding: '15px', marginBottom: '10px', borderRadius: '10px', }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                  {items.state !== 'cancelled' &&
                    <Stepper
                      className="step-card-list"
                      size="lg"
                      sx={{
                        width: '100%',
                        '--StepIndicator-size': '1.5rem',
                        '--Step-connectorInset': '0px',
                        [`& .${stepIndicatorClasses.root}`]: {
                          borderWidth: 2,
                        },
                        [`& .${stepClasses.root}::after`]: {
                          height: 2,
                        },
                        [`& .${stepClasses.completed}`]: {
                          [`& .${stepIndicatorClasses.root}`]: {
                            borderColor: 'primary.300',
                            // color: 'primary.300',
                          },
                          '&::after': {
                            bgcolor: 'primary.300',
                          },
                        },
                        [`& .${stepClasses.active}`]: {
                          [`& .${stepIndicatorClasses.root}`]: {
                            borderColor: 'currentColor'
                          },
                        },
                        [`& .${stepClasses.disabled} *`]: {
                          color: 'neutral.outlinedDisabledColor',
                        },
                      }}
                    >
                      <Step
                        className="step-item-list"
                        active={items.shipping_state === 'processing'}
                        completed={items.shipping_status?.split(',')?.includes('dispatched')}
                        orientation="vertical"
                        indicator={
                          <StepIndicator
                            variant={items.shipping_status?.split(',')?.includes('dispatched') ? 'solid' : 'outlined'}
                            color={items.shipping_status?.split(',')?.includes('dispatched') ? 'success' : items.shipping_state === 'processing' ? 'primary' : 'neutral'}>

                            {items.shipping_status?.split(',')?.includes('dispatched') ?
                              <CheckCircleRoundedIcon style={{ fontSize: '15px' }} /> :
                              <FiberManualRecordIcon className='checkthiss' style={{ fontSize: '15px' }} />
                            }
                          </StepIndicator>
                        }
                      >
                        <Typography
                          style={{ gap: '1px' }}
                          sx={{
                            textTransform: 'uppercase',
                            fontWeight: 'lg',
                            fontSize: '0.50rem',
                            letterSpacing: '0.5px',
                            gap: '2px'
                          }}
                        >
                          Processing
                        </Typography>
                      </Step>

                      <Step
                        className="step-item-list"
                        active={items.shipping_state === 'dispatched'}
                        completed={getShippingStep(items.shipping_status)}
                        orientation="vertical"
                        indicator={
                          <StepIndicator
                            variant={getShippingStep(items.shipping_status) ? 'solid' : 'outlined'}
                            color={getShippingStep(items.shipping_status) ? 'success' : items.shipping_state === 'dispatched' ? 'primary' : 'neutral'}>
                            {getShippingStep(items.shipping_status) ?
                              <CheckCircleRoundedIcon style={{ fontSize: '15px' }} /> :
                              <LocalMallIcon style={{ fontSize: '15px' }} />
                            }
                          </StepIndicator>
                        }
                      >
                        <Typography
                          sx={{
                            textTransform: 'uppercase',
                            fontWeight: 'lg',
                            fontSize: '0.50rem',
                            letterSpacing: '0.5px',
                          }}
                        >
                          Procuring
                        </Typography>
                      </Step>
                      <Step
                        className="step-item-list"
                        active={getShippingStep(items.shipping_status)}
                        completed={items.shipping_status?.split(',')?.includes('delivered')}
                        orientation="vertical"
                        indicator={
                          <StepIndicator
                            variant={items.shipping_status?.split(',')?.includes('delivered') ? 'solid' : 'outlined'}
                            color={items.shipping_status?.split(',')?.includes('delivered') ? 'success' : getShippingStep(items.shipping_status) ? 'primary' : 'neutral'}>
                            {items.shipping_status?.split(',')?.includes('delivered') ?
                              <CheckCircleRoundedIcon style={{ fontSize: '15px' }} /> :
                              <LocalShippingRoundedIcon style={{ fontSize: '15px' }} />
                            }
                          </StepIndicator>
                        }
                      >
                        <Typography
                          sx={{
                            textTransform: 'uppercase',
                            fontWeight: 'lg',
                            fontSize: '0.50rem',
                            letterSpacing: '0.5px',
                          }}
                        >
                          Shipping
                        </Typography>
                      </Step>
                      <Step
                        className="step-item-list"
                        active={items.shipping_status?.split(',')?.includes('delivered')}
                        completed={items.shipping_status?.split(',')?.includes('delivered')}
                        orientation="vertical"
                        indicator={
                          <StepIndicator
                            variant={items.shipping_status?.split(',')?.includes('delivered') ? 'solid' : 'outlined'}
                            color={items.shipping_status?.split(',')?.includes('delivered') ? 'primary' : 'neutral'}>
                            {items.shipping_status?.split(',')?.includes('delivered') ?
                              <CheckCircleRoundedIcon style={{ fontSize: '15px' }} /> :
                              <CheckCircleRoundedIcon style={{ fontSize: '15px' }} />
                            }
                          </StepIndicator>
                        }
                      >
                        <Typography
                          sx={{
                            textTransform: 'uppercase',
                            fontWeight: 'lg',
                            fontSize: '0.50rem',
                            letterSpacing: '0.5px',
                          }}
                        >
                          Delivered
                        </Typography>
                      </Step>
                    </Stepper>
                  }
                </div>
                {items.state === 'cancelled' &&
                  <div className='badge badge-danger' style={{ marginBottom: '10px', fontSize: '11px', }}>Item has been cancelled</div>
                }
                {items.delivered_on &&
                  <>
                    {items.shipping_state === 'delivered' &&
                      <div style={{ fontSize: '11px', marginBottom: '10px', whiteSpace: 'normal' }} className='badge badge-success'>
                        {t('delivered_on')}:&nbsp;{moment(items.delivered_on).format('Do MMM YYYY HH:mm')}
                      </div>
                    }
                  </>
                }
                <div className='op-item-card'>
                  <img className='op-item-img' src={items.image} />
                  <div className='op-item-title'>
                    <div>
                      <p style={{ margin: 0 }}>{items.title?.replace(/[^\w\s.,/:']/gi, ' ')}</p>
                    </div>
                    <div className='op-item-content'>
                      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                        <Tag>{formatPrice(items.price, items.currency ? items.currency : userData?.contant?.currency, 0)}</Tag>
                        <Tag>Condition: {items.conditions}</Tag>
                        <Tag>Qty.: {items.quantity}</Tag>
                      </div>
                    </div>
                    {items.current_variant &&
                      <div style={{ padding: '5px 0px 5px 0px', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                        {Object.entries(items.current_variant || []).map(([key, value], index) => (
                          <Tag style={{ fontSize: '10px' }} key={index}><strong>{key}:</strong> {value}</Tag>
                        ))}
                      </div>
                    }
                  </div>
                </div>

                {items.state !== 'cancelled' &&
                  <>
                    <IconButton
                      style={{ minHeight: '30px', padding: '0px 10px 0px 10px', borderRadius: '6px' }}
                      size='sm'
                      variant='outlined'
                      onClick={(() => openTracking(isExpand === `open${items.id}` ? '' : `open${items.id}`))}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ fontSize: '13px' }}>Item Tracking</span>
                        {isExpand === `open${items.id}` && <ExpandLessIcon style={{ fontSize: '22px' }} />}
                        {isExpand !== `open${items.id}` && <ExpandMoreIcon style={{ fontSize: '22px' }} />}
                      </div>
                    </IconButton>

                    {isExpand === `open${items.id}` &&
                      <Stepper
                        style={{ marginTop: '20px' }}
                        orientation="vertical"
                        sx={{
                          '--Stepper-verticalGap': '1.2rem',
                          '--StepIndicator-size': '1.2rem',
                          '--Step-gap': '1rem',
                          '--Step-connectorInset': '0.1rem',
                          '--Step-connectorRadius': '1rem',
                          '--Step-connectorThickness': '3px',
                          '--joy-palette-success-solidBg': 'var(--joy-palette-success-400)',

                          [`& .${stepClasses.completed}`]: {
                            '&::after': { bgcolor: 'success.solidBg' },
                          },
                          [`& .${stepClasses.active}`]: {
                            [`& .${stepIndicatorClasses.root}`]: {
                              border: '2px solid',
                              borderColor: '#fff',
                              boxShadow: (theme) => `0 0 0 1px ${theme.vars.palette.primary[500]}`,
                            },
                          },
                          [`& .${stepClasses.disabled} *`]: {
                            color: 'neutral.softDisabledColor',
                          },
                          [`& .${typographyClasses['title-sm']}`]: {
                            textTransform: 'uppercase',
                            letterSpacing: '1px',
                            fontSize: '10px',
                          },
                        }}
                      >
                        <Step
                          active={items.shipping_state === 'processing'}
                          completed={items.shipping_status?.split(',')?.includes('dispatched')}
                          indicator={
                            <StepIndicator
                              variant={items.shipping_status?.split(',')?.includes('dispatched') ? 'solid' : 'solid'}
                              color={items.shipping_status?.split(',')?.includes('dispatched') ? 'success' : items.shipping_state === 'processing' ? 'primary' : 'neutral'}>
                              {items.shipping_status?.split(',')?.includes('dispatched') ?
                                <CheckCircleRoundedIcon style={{ fontSize: '14px' }} /> :
                                <span style={{ fontSize: '12px' }}>1</span>
                              }
                            </StepIndicator>
                          }
                        >
                          <div style={{ fontSize: '13px' }}>
                            <Typography level="title-sm">Estimated 1 day</Typography>
                            Processing
                          </div>
                        </Step>

                        <Step
                          disabled={!items.shipping_status?.split(',')?.includes('dispatched')}
                          active={items.shipping_state === 'dispatched'}
                          completed={getShippingStep(items.shipping_status)}
                          indicator={
                            <StepIndicator
                              variant={items.shipping_status?.split(',')?.includes('dispatched') ? 'solid' : 'outlined'}
                              color={getShippingStep(items.shipping_status) ? 'success' : items.shipping_state === 'dispatched' ? 'primary' : 'neutral'}>
                              {getShippingStep(items.shipping_status) ?
                                <CheckCircleRoundedIcon style={{ fontSize: '15px' }} /> :
                                <span style={{ fontSize: '12px' }}>2</span>
                              }
                            </StepIndicator>
                          }
                        >
                          <div style={{ fontSize: '13px' }}>
                            <Typography level="title-sm">
                              {items.country === 'AE' ? 'Estimated 1-2 days' : 'Estimated 2-3 days'}
                            </Typography>
                            Procuring
                          </div>
                        </Step>

                        <Step
                          disabled={!items.shipping_status?.split(',')?.includes('dispatched')}
                          active={items.shipping_state === 'dispatched'}
                          completed={getShippingStep(items.shipping_status)}
                          indicator={
                            <StepIndicator
                              variant={getShippingStep(items.shipping_status) ? 'solid' : 'outlined'}
                              color={getShippingStep(items.shipping_status) ? 'success' : items.shipping_state === 'dispatched' ? 'primary' : 'neutral'}>
                              {getShippingStep(items.shipping_status) ?
                                <CheckCircleRoundedIcon style={{ fontSize: '14px' }} /> :
                                <FiberManualRecordIcon style={{ fontSize: '14px' }} />
                              }
                            </StepIndicator>}>

                          <div style={{ fontSize: '13px' }}>
                            Dsipatched from Supplier
                          </div>
                        </Step>


                        <Step
                          disabled={!items.shipping_status?.split(',')?.includes(`arrived_in_${items.country}`)}
                          active={getShippingStep1(items.shipping_state)}
                          completed={items.shipping_status?.split(',')?.includes('delivered')}
                          indicator={
                            <StepIndicator
                              variant={getShippingStep(items.shipping_status) ? 'solid' : 'outlined'}
                              color={items.shipping_status?.split(',')?.includes('delivered') ? 'success' : getShippingStep(items.shipping_status) ? 'primary' : 'neutral'}>
                              {items.shipping_status?.split(',')?.includes('delivered') ?
                                <CheckCircleRoundedIcon style={{ fontSize: '14px' }} /> :
                                <span style={{ fontSize: '12px' }}>3</span>
                              }
                            </StepIndicator>}>
                          <div style={{ fontSize: '13px' }}>
                            <Typography level="title-sm">
                              {items.country === 'AE' ? 'Estimated 1-2 days' : 'Estimated 3-5 days'}
                            </Typography>
                            Shipping and Fulfillment
                          </div>
                        </Step>
                        {items.country !== 'AE' &&
                          <>
                            <Step
                              disabled={!items.shipping_status?.split(',')?.includes(`arrived_in_${items.country}`)}
                              active={items.shipping_state === `arrived_in_${items.country}`}
                              completed={items.shipping_status?.split(',')?.includes(`out_for_delivery`)}
                              indicator={
                                <StepIndicator
                                  variant={items.shipping_status?.split(',')?.includes(`departed_from_${items.country}`) ? 'solid' : 'outlined'}
                                  color={items.shipping_status?.split(',')?.includes(`out_for_delivery`) ? 'success' : items.shipping_status?.split(',')?.includes(`arrived_in_${items.country}`) ? 'primary' : 'neutral'}>
                                  {items.shipping_status?.split(',')?.includes(`departed_from_${items.country}`) ?
                                    <CheckCircleRoundedIcon style={{ fontSize: '14px' }} /> :
                                    <FiberManualRecordIcon style={{ fontSize: '14px' }} />
                                  }
                                </StepIndicator>}>
                              <div style={{ fontSize: '13px' }}>
                                Arrived in {items.country} warehouse
                              </div>
                            </Step>

                            <Step
                              disabled={!items.shipping_status?.split(',')?.includes(`departed_from_${items.country}`)}
                              active={items.shipping_state === `departed_from_${items.country}`}
                              completed={items.shipping_status?.split(',')?.includes(`out_for_delivery`)}
                              indicator={
                                <StepIndicator
                                  variant={items.shipping_status?.split(',')?.includes(`arrived_in_uae`) ? 'solid' : 'outlined'}
                                  color={items.shipping_status?.split(',')?.includes(`out_for_delivery`) ? 'success' : items.shipping_status?.split(',')?.includes(`departed_from_${items.country}`) ? 'primary' : 'neutral'}>
                                  {items.shipping_status?.split(',')?.includes(`arrived_in_uae`) ?
                                    <CheckCircleRoundedIcon style={{ fontSize: '14px' }} /> :
                                    <FiberManualRecordIcon style={{ fontSize: '14px' }} />
                                  }
                                </StepIndicator>}>
                              <div style={{ fontSize: '13px' }}>
                                Departed from {items.country} warehouse
                              </div>
                            </Step>
                          </>
                        }
                        <Step
                          disabled={!items.shipping_status?.split(',')?.includes(`arrived_in_uae`)}
                          active={items.shipping_state === `arrived_in_uae`}
                          completed={items.shipping_status?.split(',')?.includes(`out_for_delivery`)}
                          indicator={
                            <StepIndicator
                              variant={items.shipping_status?.split(',')?.includes(`departed_from_uae`) ? 'solid' : 'outlined'}
                              color={items.shipping_status?.split(',')?.includes(`out_for_delivery`) ? 'success' : items.shipping_status?.split(',')?.includes(`arrived_in_uae`) ? 'primary' : 'neutral'}>
                              {items.shipping_status?.split(',')?.includes(`departed_from_uae`) ?
                                <CheckCircleRoundedIcon style={{ fontSize: '14px' }} /> :
                                <FiberManualRecordIcon style={{ fontSize: '14px' }} />
                              }
                            </StepIndicator>}>
                          <div style={{ fontSize: '13px' }}>
                            Arrived in UAE warehouse
                          </div>
                        </Step>

                        <Step
                          disabled={!items.shipping_status?.split(',')?.includes(`departed_from_uae`)}
                          active={items.shipping_state === `departed_from_uae`}
                          completed={items.shipping_status?.split(',')?.includes(`out_for_delivery`)}
                          indicator={
                            <StepIndicator
                              variant={items.shipping_status?.split(',')?.includes(`out_for_delivery`) ? 'solid' : 'outlined'}
                              color={items.shipping_status?.split(',')?.includes(`out_for_delivery`) ? 'success' : items.shipping_status?.split(',')?.includes(`departed_from_uae`) ? 'primary' : 'neutral'}>
                              {items.shipping_status?.split(',')?.includes(`out_for_delivery`) ?
                                <CheckCircleRoundedIcon style={{ fontSize: '14px' }} /> :
                                <FiberManualRecordIcon style={{ fontSize: '14px' }} />
                              }
                            </StepIndicator>}>
                          <div style={{ fontSize: '13px' }}>
                            Departed from UAE warehouse
                          </div>
                        </Step>

                        <Step
                          disabled={!items.shipping_status?.split(',')?.includes(`out_for_delivery`)}
                          active={items.shipping_state === `out_for_delivery`}
                          completed={items.shipping_status?.split(',')?.includes(`delivered`)}
                          indicator={
                            <StepIndicator
                              variant={items.shipping_status?.split(',')?.includes(`delivered`) ? 'solid' : 'outlined'}
                              color={items.shipping_status?.split(',')?.includes(`delivered`) ? 'success' : items.shipping_status?.split(',')?.includes(`out_for_delivery`) ? 'primary' : 'neutral'}>
                              {items.shipping_status?.split(',')?.includes(`delivered`) ?
                                <CheckCircleRoundedIcon style={{ fontSize: '14px' }} /> :
                                <FiberManualRecordIcon style={{ fontSize: '14px' }} />
                              }
                            </StepIndicator>}>
                          <div style={{ fontSize: '13px' }}>
                            Out for delivery
                          </div>
                        </Step>
                        <Step
                          disabled={!items.shipping_status?.split(',')?.includes(`delivered`)}
                          // active={items.shipping_state === `delivered`}
                          completed={items.shipping_status?.split(',')?.includes(`delivered`)}
                          indicator={<StepIndicator
                            variant={items.shipping_status?.split(',')?.includes(`delivered`) ? 'solid' : 'outlined'}
                            color={items.shipping_status?.split(',')?.includes(`delivered`) ? 'success' : items.shipping_state === `delivered` ? 'primary' : 'neutral'}><LocalShippingRoundedIcon style={{ fontSize: '13px' }} /></StepIndicator>}>
                          <div style={{ fontSize: '13px' }}>
                            <Typography level="title-sm">
                              {items.country === 'AE' ? 'Estimated 1-2 days' : 'Estimated 2-3 days'}
                            </Typography>
                            Delivered
                          </div>
                        </Step>
                      </Stepper>
                    }
                  </>
                }
              </div>
            )}
          </div>
        </div >
      ))
      }
    </>
  );
}

export default OrdersList;
import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux";
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import { makeStyles } from '@material-ui/core/styles';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useGetUserDataQuery } from '../../services/login';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  imageList: {
    "&::-webkit-scrollbar": {
      display: "none"
    }, /* Chrome */
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));

function ViewedProducts({ relatedData, productId }) {
  const { userId, userToken, cartToken, languageCode, countryCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const { t } = useTranslation()
  const { data: userData } = useGetUserDataQuery({ userId: userId, userToken: userToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })

  const classes = useStyles();
  function productTitle(title) {
    return title?.replace(/[^A-Za-z0-9]/g, ' ')?.replace(/[^\w\s]/gi, ' ')?.replace(/ /g, '-')
  }

  const handleError = (e) => {
    console.error('Error loading image:', e.target.src);
    e.target.src = 'https://cdn.ebtida.com/1705315901965-Image-not-found.jpg';
  };

  function functionIdLink(id, pid, store_id) {
    if (Number(store_id) === 3) {
      return pid
    } else {
      return id
    }
  }

  const reversedItems = [...relatedData].reverse();

  return (
    <>
      <div className="container">
        {relatedData?.length > 0 &&
          <h1 className="cardTitle">{t('Recently Visited')}</h1>
        }
        <ImageList className={classes.imageList} cols={6}>
          {reversedItems?.map((item, i) =>
            item.id !== productId &&
            <ImageListItem key={i} className="cardItem">
              <Link
                to={`/product/${item?.store_id}/${functionIdLink(item?.id, item?.pid, item?.store_id)}/${productTitle(item?.title)}`} className="cardItem-item"
                style={{ border: '1px solid #dbdbdb' }}>
                <div className='img-card imgCard' style={{ height: '150px' }}>
                  <img
                    style={{ height: '150px' }}
                    className="img-item"
                    src={item?.image}
                    onError={handleError}
                  />
                </div>
                <div className="card-content" style={{ height: '75px' }}>
                  <div className='title-card'>
                    <h4>{item?.title?.replace(/[^\w\s.,'/:]/gi, ' ')}</h4>
                  </div>
                </div>
              </Link>
            </ImageListItem>
          )}
        </ImageList>

      </div>
    </>
  );
}

export default ViewedProducts;

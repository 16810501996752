import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useGetOrderQuery } from '../../../services/membership';
import { useSelector } from "react-redux";
import Host from '../../../Components/Host';
import Button from '@mui/joy/Button';
var moment = require('moment');
function OrdersList({ orders }) {
  const { userId, userToken, userEmail, countryCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()

  if (!userEmail && !userToken) {
    history.push('/')
  }

  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 24
  const membership = useGetOrderQuery({
    page: page,
    size: limit,
    userId: userId,
    userToken: userToken,
    domain: Host?.domain,
    countryCode: countryCode
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const membershipData = membership?.data?.order_items
  const pagination = membership?.data?.pagination

  const groupData = orders?.order_items?.reduce((acc, obj) => {
    const key = obj.estimated_delivery;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});

  function geBorderColor(states, state) {
    if (state === 'cancelled') {
      return '1px solid red'
    } else {
      if (states === 'delivered') {
        return '1px solid green'
      } else if (states === 'dispatched') {
        return '1px solid orange'
      } else if (states === 'shipping') {
        return '1px solid orange'
      } else {
        return '1px solid #eee'
      }
    }
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        {groupData && Object?.entries(groupData).map(([group, groupItems]) => (
          <div
            key={group}
            style={{
              background: '#f0f4f8',
              marginBottom: '15px',
              backgroundColor: '#fff0'
            }}
          >
            <div className='op-item-section' style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              {groupItems?.map((items, index) =>
                <Link
                  to={`/account/order/${orders?.order_number}`}
                  key={index}
                  style={{ background: '#fff', borderRadius: '10px', margin: '0px 5px' }}>
                  <div className='op-item-card' style={{ marginBottom: 0, border: geBorderColor(items.shipping_state, items.state) }}>
                    <img className='op-item-img' src={items.image} style={{ maxWidth: '65px', minWidth: '65px', minHeight: '65px', maxHeight: '65px' }} />
                  </div>
                </Link>
              )}
            </div>
          </div>
        ))}
      </div>
      <div style={{ marginBottom: '10px' }}>
        <Button
          style={{ fontSize: '14px', fontWeight: 500 }}
          component={Link}
          variant='outlined'
          color='warning'
          size='sm'
          to={`/account/order/${orders?.order_number}`} >
          {t('view_details')}
        </Button>
      </div>
    </>
  );
}

export default OrdersList;
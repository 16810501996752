import React, { useState, useEffect, Fragment } from 'react';

const goShopImages = [
  {
    link: 'makeup',
    image: 'https://cdn.ebtida.com/1702926227089-Makeup.jpg'
  },
  {
    link: 'beauty',
    image: 'https://cdn.ebtida.com/1702926258508-Haircare--(Beauty).jpg'
  },
  {
    link: 'appliances',
    image: 'https://cdn.ebtida.com/1704888536066-styling.jpg'
  }
]

const thwiftyImages = [
  {
    link: 'coleman',
    image: 'https://cdn.ebtida.com/1704191487441-Coleman-3.jpg',
  },
  {
    link: 'drunk elephant',
    image: 'https://cdn.ebtida.com/1704191472445-Drunk-elephant-2.jpg',
  },
  {
    link: 'stanley',
    image: 'https://cdn.ebtida.com/1704191453120-Stanley-3.jpg',
  },
  {
    link: 'leatherman',
    image: 'https://cdn.ebtida.com/1706872924067-LEATHERMAN.jpg',
  },
  {
    link: 'dr squatch',
    image: 'https://cdn.ebtida.com/1704191481634-Dr-Squatch-2.jpg'
  }
]


const thwiftyAll = 'thwifty'
const goshoprightAll = 'goshopright'

const hostName = window.location.hostname?.split('.')


let domain;
let domainName;
let domainLogo;
let bgColor;
let favicon;
let email;
let metaTitle;
let metaDescription;
let headerBg;
let subMenu;
let bannesImages = [];
let buttonColor;
let address;
let phone;

if (hostName?.includes(thwiftyAll)) {
  domain = 'thwifty.com'
  domainName = 'Thwifty'
  domainLogo = 'https://cdn.ebtida.com/1703526807565-thwifty.png'
  bgColor = '#fe7f4a'
  favicon = 'https://cdn.ebtida.com/1703526815046-favicon-thwifty.png'
  email = 'help@thwifty.com'
  metaTitle = 'Thwifty: Your One-Stop Shop for Affordable Deals and Quality Products'
  metaDescription = 'Experience the best shopping experience at affordable prices with Thwifty. Discover a wide range of products from top brands and enjoy incredible savings. Shop smart and save big with Thwifty today'
  headerBg = '#fff'
  subMenu = '#fff'
  bannesImages = thwiftyImages
  buttonColor = '#f7f7f7',
  address = 'King Khaled Muhammad Abdullah <br />Al Zahid Street, Building 154,<br /> Hor Al Anz, Dubai',
  phone = '+971 4 836 9553'
} else if (hostName?.includes(goshoprightAll)) {
  domain = 'goshopright.com'
  domainName = 'Goshopright'
  domainLogo = 'https://cdn.ebtida.com/1703017248524-32(1).png'
  bgColor = '#e0e9d2'
  favicon = 'https://cdn.ebtida.com/1703526901758-Favicon.png'
  email = 'support@goshopright.com'
  metaTitle = 'Discover Exclusive International Brands at GoShopRight.com - Your Ultimate E-commerce Destination.'
  metaDescription = 'Discover an unparalleled shopping experience at goshopright.com. Explore our vast selection of collections sourced from international vendors, all at unbeatable prices. Shop now and find incredible bargains on the latest trends and must-have items.'
  headerBg = '#e0e9d2'
  subMenu = '#fff'
  bannesImages = goShopImages
  buttonColor = '#030303',
  address = 'King Khaled Muhammad Abdullah <br />Al Zahid Street, Building 154, <br />Hor Al Anz, Dubai',
  phone = '+971 4 836 9557'
} else {
  domain = 'goshopright.com'
  domainName = 'Goshopright'
  domainLogo = 'https://cdn.ebtida.com/1703017248524-32(1).png'
  bgColor = '#e0e9d2'
  favicon = 'https://cdn.ebtida.com/1703526901758-Favicon.png'
  email = 'support@goshopright.com'
  metaTitle = 'Discover Exclusive International Brands at GoShopRight.com - Your Ultimate E-commerce Destination.'
  metaDescription = 'Discover an unparalleled shopping experience at goshopright.com. Explore our vast selection of collections sourced from international vendors, all at unbeatable prices. Shop now and find incredible bargains on the latest trends and must-have items.'
  headerBg = '#e0e9d2'
  subMenu = '#fff'
  bannesImages = goShopImages
  buttonColor = '#030303',
  address = '',
  phone = '+971 4 836 9557'
}



const Host = {
  domain: domain,
  domainName: domainName,
  domainLogo: domainLogo,
  bgColor: bgColor,
  favicon: favicon,
  email: email,
  metaTitle: metaTitle,
  metaDescription: metaDescription,
  headerBg: headerBg,
  subMenu: subMenu,
  bannesImages: bannesImages,
  buttonColor: buttonColor,
  address: address,
  phone: phone
}


export default Host;
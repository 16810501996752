import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux";
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ProductCarouselSkeleton from '../ProductCarouselSkeleton';
import { makeStyles } from '@material-ui/core/styles';
import 'react-lazy-load-image-component/src/effects/blur.css';
import GradeIcon from '@mui/icons-material/Grade';
import { useGetUserDataQuery } from '../../services/login';
import formatPrice from '../formatPrice';
var moment = require('moment');

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  imageList: {
    "&::-webkit-scrollbar": {
      display: "none"
    }, /* Chrome */
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));

function RelatedProducts({ relatedData, isLoading }) {
  const { userId, userToken, cartToken, languageCode, countryCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const { t } = useTranslation()
  const { data: userData } = useGetUserDataQuery({ userId: userId, userToken: userToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })

  const classes = useStyles();
  function productTitle(title) {
    return title?.replace(/[^A-Za-z0-9]/g, ' ')?.replace(/[^\w\s]/gi, ' ')?.replace(/ /g, '-')
  }

  let adcPosition
  if (document.body.dir === 'rtl') {
    adcPosition = { left: '10px' }
  } else {
    adcPosition = { right: '10px' }
  }

  const handleError = (e) => {
    console.error('Error loading image:', e.target.src);
    e.target.src = 'https://cdn.ebtida.com/1705315901965-Image-not-found.jpg';
  };


  function getDeliverydays(delivery) {
    const deliveryDaya = Number(delivery) + Number(userData?.contant?.delivery_days || 0)
    function getTimeline() {
      if (Number(deliveryDaya) === 1) {
        return <>{t('delivery_by')} <strong>{t('tomorrow')}</strong> {moment().add(Number(deliveryDaya), 'days').format('Do')}</>
      } else {
        return <>{t('delivery_by')} {moment().add(Number(deliveryDaya), 'days').format('Do MMM')}</>
      }
    }
    if (Number(deliveryDaya) > 0) {
      return getTimeline()
    } else {
      return `${t('delivery_by')} ${moment().add(10, 'days').format('Do MMM')}`
    }
  }

  const soteIds = [3, 1]

  function functionIdLink(id, pid, store_id) {
    if (soteIds?.includes(Number(store_id))) {
      return pid
    } else {
      return id
    }
  }

  // function functionIdLink(id, pid, store_id) {
  //   if (Number(store_id) === 3) {
  //     return pid
  //   } else {
  //     return id
  //   }
  // }

  return (
    <>
      <div className="container">
        {relatedData?.length > 0 &&
          <h1 className="cardTitle">{t('you_might_be_interested')}</h1>
        }
        {isLoading ?
          <ProductCarouselSkeleton />
          :
          <ImageList className={classes.imageList} cols={6}>
            {relatedData?.map((item, i) =>
              <ImageListItem key={i} className="cardItem">
                <Link
                  // to={`/product/${item?.store_id}/${functionIdLink(item?.id, item?.pid, item?.store_id)}/${productTitle(item?.title)}${item.asin && `?variant=${item.asin}`}`}
                  to={`/product/${item?.store_id}/${functionIdLink(item?.id, item?.pid, item?.store_id)}/${productTitle(item?.title)}`}
                  className="cardItem-item">
                  <div className='img-card imgCard'>
                    <img
                      className="img-item"
                      src={item?.image}
                      alt={item?.title}
                      onError={handleError}
                    />
                    <div className='delivery-days'
                      style={{ position: 'absolute', right: '10px', bottom: 0, color: '#000', fontSize: '12px', lineHeight: 1, padding: '1px 5px 1px 5px', borderRadius: '2px' }}><small><i>{getDeliverydays(item.estimated_delivery)}</i></small></div>
                  </div>
                  <div className="card-content">
                    <div className='title-card'>
                      <h4>{item?.title?.replace(/[^\w\s.,'/:]/gi, ' ')}</h4>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        {item?.brand && <div className='brand'>{item?.brand}</div>}
                        {item?.rating && Number(item?.rating) > 0 ?
                          <div className='rating' style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ display: 'flex', alignItems: 'center', color: '#a8a8a8', margin: '0px 3px', fontSize: '14px' }}>{item?.rating}</span>
                            <GradeIcon style={{ color: '#fe7f4a', fontSize: '1rem', marginTop: '-1.5px' }} />
                          </div> : ''
                        }
                      </div>
                    </div>
                    <div className='content-item'>
                      <h5>{formatPrice(item?.prices?.value, item?.prices?.currency, 0)}</h5>
                    </div>
                    <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                      <div style={{ fontSize: '12px', lineHeight: 0, margin: '0 5px', color: '#444' }}>
                        {t('from')}
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          style={{ width: '20px', objectFit: 'contain' }}
                          loading="lazy"
                          src={`https://flagcdn.com/w20/${item?.country?.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${item?.country?.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        <div style={{ fontSize: '12px', lineHeight: 0, margin: '0 5px', color: '#444' }}>{item?.country}</div>
                      </div>
                    </div>
                  </div>
                </Link>
              </ImageListItem>
            )}
          </ImageList>
        }
      </div>
    </>
  );
}

export default RelatedProducts;

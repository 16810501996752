import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import { useTranslation } from 'react-i18next'
import 'react-loading-skeleton/dist/skeleton.css'
import { useSelector } from "react-redux";
import Host from '../../Components/Host';
import { useGetUserDataQuery } from '../../services/login';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import HelmetComponent from '../../Components/HelmetComponent';

function ReturnsRefund() {
  const { userId, userToken, userEmail, userName, isMember, isPaymentMethod, languageCode, countryCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const [getSearchId, setSearchId] = useState("")

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  const { data: userData } = useGetUserDataQuery({ userId: userId, userToken: userToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })


  const myElementRef = React.useRef(null);
  const myElementRefs = React.useRef(null);
  const myContact = React.useRef(null);

  const myPayment = React.useRef(null);
  const myBilling = React.useRef(null);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      if (hash === 'returns') {
        myElementRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      if (hash === 'shipping') {
        myElementRefs.current.scrollIntoView({ behavior: 'smooth' });
      }
      if (hash === 'contact') {
        myContact.current.scrollIntoView({ behavior: 'smooth' });
      }
      if (hash === 'billing') {
        myBilling.current.scrollIntoView({ behavior: 'smooth' });
      }
      if (hash === 'payment') {
        myPayment.current.scrollIntoView({ behavior: 'smooth' });
      }
    };
    window.addEventListener('hashchange', handleHashChange);
    handleHashChange();
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [window, window.location.hash]);

  return (
    <>
      <HelmetComponent title="Return refunds" />
      <div className="statisc-page">
        <div className="container static-container">
          <div className="row static-row">
            <div className="col-md-10">
              <div className="static-container-card"
                style={{ textAlign: 'left', padding: '20px', background: '#fcfcfc', border: '1px solid #eee', borderRadius: '7px' }}>
                <h1 style={{ padding: '20px 0px 20px 0px', fontWeight: 300, fontSize: '1.4rem' }}>
                  {t('Returns, Warranty and Refunds')}
                </h1>

                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>
                  Your order is covered by {Host?.domainName} Protect which offers coverage:
                </p>
                <ol>
                  <li>If an item is defective or damaged on arrival. Contact us to get it returned or refunded immediately within 3 days of receiving the item.</li>
                  <li>Warranty coverage of an item if provided by the manufacturer is provided for 1 year and we shall cover expenses of shipping an item back (unless it is a customized order).</li>
                  <li>30 Day hassle free returns. If you’ve changed your mind about the item just send us back the item in its original packaging and we’ll refund the item amount back to you.</li>
                </ol>

                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>
                  To start a return, you can contact us at {Host?.email}. Please note that returns will need to be sent to the following address:
                </p>
                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>
                  <p style={{ lineHeight: 2 }}>
                  <div dangerouslySetInnerHTML={{
                      __html: Host?.address
                    }}>
                    </div>
                    <MailOutlineIcon style={{ fontSize: '18px' }} /> {Host?.email} <br />
                    <PhoneIcon style={{ fontSize: '18px' }} />{Host?.phone}<br />
                  </p>
                </p>

                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>
                  If your return is accepted, we’ll send you a return shipping label, as well as instructions on how and where to send your package. Items sent back to us without first requesting a return will not be accepted.
                </p>

                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>You can always contact us for any return questions at {Host?.email}.</p>

                <h4>Damages and issues</h4>
                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>
                  Please inspect your order upon reception and contact us immediately if the item is defective, or damaged or if you receive the wrong item so that we can evaluate the issue and make it right.
                </p>
                <h4>Exceptions / non-returnable items</h4>
                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>
                  Certain types of items cannot be returned, like perishable goods (such as food, flowers, or plants), custom products (such as special orders or personalized items), and personal care goods (such as beauty products). We also do not accept returns for hazardous materials, flammable liquids, or gasses. Please get in touch if you have questions or concerns about your specific item.
                </p>
                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>
                  Unfortunately, we cannot accept returns on sale items or gift cards.
                </p>
                <h4>Exchanges</h4>
                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>
                  The fastest way to ensure you get what you want is to return the item you have, and once the return is accepted, make a separate purchase for the new item.
                </p>
                <h4>Refunds</h4>
                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>
                  We will notify you once we’ve received and inspected your return, and let you know if the refund was approved or not. If approved, you’ll be automatically refunded on your original payment method within 10 business days. Please remember it can take some time for your bank or credit card company to process and post the refund too.
                </p>
                <h4>Order Cancellation</h4>
                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>
                Order cancellation is only possible if the order has not yet been dispatched from the supplier. Once the order has been dispatched it cannot be cancelled or refunded.
                </p>
                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>
                  If more than 15 business days have passed since we’ve approved your return, please contact us at {Host?.email}.
                </p>
                <p>&nbsp;</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReturnsRefund;


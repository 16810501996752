import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import "antd/dist/antd.min.css";
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { useGetUserDataQuery } from '../../services/login';
import { useDispatch, useSelector } from "react-redux";
import { removeToCart, addToLanguage, removeUserToState, addCartToken } from "../../Reducers/authReducer";
import { Helmet } from 'react-helmet-async';
import Host from '../../Components/Host';
import { useFetchQuery } from '../../services/cart';
import ActiveCountries from '../../Components/Header/ActiveCountries';
import LogRocket from 'logrocket';

function Pages(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userId, userToken, userName, userEmail, languageCode, countryCode, cartToken } = useSelector((state) => state.auth);
  const Components = props.Components;
  const checkuser = useGetUserDataQuery({ userId: userId, userToken: userToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })
  useFetchQuery({ userId: userId, cartToken: cartToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })

  if (userId && userToken) {
    LogRocket.identify(userId, {
      name: userName,
      email: userEmail
    });
  }
  LogRocket.init('txpzuu/thwifty');

  useEffect(() => {
    if (!cartToken) {
      dispatch(addCartToken({
        cartToken: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
      }));
    }
  }, [cartToken])

  if (countryCode) {
    if (!ActiveCountries?.includes(countryCode)) {
      history.push(`/login`)
    }
  }


  if (userId && userToken) {
    if (checkuser?.isSuccess) {
      if (checkuser?.data?.user) {
        if (checkuser?.data?.user?.user_token !== userToken) {
          dispatch(removeToCart());
          dispatch(removeUserToState());
        }
        if (checkuser?.data?.user?.status === 0) {
          dispatch(removeToCart());
          dispatch(removeUserToState());
        }
      }
    }
  }


  let fontFamilyForAll
  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else if (languageCode === 'urdu') {
    fontFamilyForAll = 'AlQalam Shakir'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }

  const fontFamily_Text = {
    textAlign: languageCode === 'ar' || languageCode === 'urdu' ? 'right' : 'left',
    fontFamily: fontFamilyForAll,
    display: 'block',
    minHeight: '100vh',
    position: 'relative'
  }


  const thwiftyAll = "thwifty";
  const goshoprightAll = "goshopright";
  const hostName = window.location.hostname?.split(".");
  function getImage() {
    if (hostName?.includes(thwiftyAll)) {
      return 'https://cdn.ebtida.com/1704191487441-Coleman-3.jpg'
    } else if (hostName?.includes(goshoprightAll)) {
      return 'https://cdn.ebtida.com/1702926227089-Makeup.jpg'
    } else {
      return ''
    }
  }


  return (
    <>
      <Helmet>
        <meta name="description" content={Host?.metaDescription} />
        <link rel="canonical" href={`${window.location.href}`} />
        <meta name="author" content={Host?.domainName} />
        <meta property="og:title" content={`${Host?.metaTitle}`} />
        <meta property="og:description" content={Host?.metaDescription} />
        <meta property="og:image" content={getImage()} />
        <meta property="og:url" content={`${window.location.href}`} />
        <link rel="icon" href={Host?.favicon} type="image/x-icon" />
      </Helmet>
      <div style={fontFamily_Text}>
        {props.header && <Header />}
        <Components />
        {props.footer && <Footer />}
      </div>
    </>
  );
}

export default Pages;
